import React from 'react';

export const Import = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4C3 3.44772 3.44772 3 4 3H7C7.55228 3 8 2.55228 8 2C8 1.44772 7.55228 1 7 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V12C23 11.4477 22.5523 11 22 11C21.4477 11 21 11.4477 21 12V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4ZM13.1373 10.3085C13.0236 11.6912 13.0041 13.234 13.0007 14.9439L15.9368 12.0078C16.3274 11.6173 16.9475 11.6043 17.322 11.9787C17.6964 12.3532 17.6834 12.9733 17.2929 13.3638L12.8076 17.8491C12.4171 18.2397 11.7969 18.2527 11.4225 17.8782L7.12168 13.5774C6.74722 13.2029 6.76024 12.5828 7.15076 12.1923C7.54129 11.8017 8.16143 11.7887 8.53589 12.1632L11.0015 14.6287C11.0067 13.0275 11.0311 11.5185 11.144 10.1446C11.2885 8.38686 11.5828 6.76756 12.217 5.39344C12.8643 3.99097 13.858 2.86197 15.3409 2.1022C16.7977 1.35583 18.6593 1 21 1C21.5523 1 22 1.44772 22 2C22 2.55228 21.5523 3 21 3C18.8407 3 17.3273 3.33167 16.2529 3.88218C15.2045 4.41928 14.5107 5.19653 14.033 6.23156C13.5422 7.29494 13.274 8.64439 13.1373 10.3085Z"
      fill="currentColor"
    />
  </svg>
);
