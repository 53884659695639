import React, { ReactNode } from 'react';
import cx from 'classnames';

import styles from './Header1.module.scss';

interface Header1Props {
  children: ReactNode;
  align?: 'center' | 'left';
}

export const Header1 = ({ children, align }: Header1Props) => {
  return (
    <h1 className={cx(styles.header1, styles[`header1--${align}`])}>
      {children}
    </h1>
  );
};
