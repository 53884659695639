import React from 'react';

export const Reset = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6 1.80006C15.0418 1.46869 15.1314 0.841887 14.8 0.400059C14.4686 -0.0417689 13.8418 -0.131312 13.4 0.200059L9.4 3.20006C8.95817 3.53143 8.86863 4.15823 9.2 4.60006L12.2 8.60006C12.5314 9.04189 13.1582 9.13143 13.6 8.80006C14.0418 8.46869 14.1314 7.84189 13.8 7.40006L12 5.00006C16.9706 5.00006 21 9.0295 21 14.0001C21 18.9706 16.9706 23.0001 12 23.0001C7.02944 23.0001 3 18.9706 3 14.0001C3 13.4478 2.55228 13.0001 2 13.0001C1.44772 13.0001 1 13.4478 1 14.0001C1 20.0752 5.92487 25.0001 12 25.0001C18.0751 25.0001 23 20.0752 23 14.0001C23 8.24376 18.5785 3.52017 12.9465 3.04021L14.6 1.80006Z"
      fill="currentColor"
    />
  </svg>
);
