import React from 'react';

export const Check = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6253 5.21968C21.0563 5.56504 21.1257 6.19439 20.7804 6.62537L11.9655 17.6254C11.7894 17.8452 11.5288 17.9805 11.2477 17.9981C10.9666 18.0157 10.6911 17.9139 10.4889 17.7178L4.30372 11.7178C3.90731 11.3333 3.89769 10.7002 4.28223 10.3038C4.66678 9.90734 5.29987 9.89772 5.69628 10.2823L11.0923 15.5168L19.2196 5.3747C19.565 4.94372 20.1944 4.87432 20.6253 5.21968Z"
      fill="currentColor"
    />
  </svg>
);
