import React from 'react';

export const Login = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0667 18.8889L19.0667 1.11111C19.0667 0.49746 18.5593 0 17.9333 0H11.1111C10.4975 0 10 0.49746 10 1.11111C10 1.72476 10.4975 2.22222 11.1111 2.22222L16.8 2.22222L16.8 17.7778H11.1111C10.4975 17.7778 10 18.2752 10 18.8889C10 19.5025 10.4975 20 11.1111 20L17.9333 20C18.5593 20 19.0667 19.5025 19.0667 18.8889ZM0 10C0 9.44771 0.447715 9 1 9H9.27205L6.63604 6.364C6.24552 5.97347 6.24552 5.34031 6.63604 4.94978C7.02657 4.55926 7.65973 4.55926 8.05026 4.94978L12.3934 9.29293C12.7839 9.68345 12.7839 10.3166 12.3934 10.7071L8.05026 15.0503C7.65973 15.4408 7.02657 15.4408 6.63604 15.0503C6.24552 14.6598 6.24552 14.0266 6.63604 13.6361L9.27212 11H1C0.447715 11 0 10.5523 0 10Z"
      fill="currentColor"
    />
  </svg>
);
