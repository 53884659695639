import React from 'react';

export const Eye = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2C11.4477 2 11 2.44772 11 3V5.03913C9.03261 5.19575 7.45812 5.80742 6.21286 6.60073L5.11603 4.70096C4.83988 4.22267 4.22829 4.05879 3.75 4.33494C3.27171 4.61108 3.10783 5.22267 3.38398 5.70096L4.62595 7.85213C2.75443 9.65275 2.06348 11.6474 2.052 11.684C1.98302 11.8894 1.98302 12.1116 2.052 12.317C2.073 12.383 4.367 19 12 19C19.633 19 21.927 12.383 21.948 12.316C22.017 12.1106 22.017 11.8884 21.948 11.683C21.9362 11.6459 21.2062 9.5389 19.2135 7.70075L20.7678 6.14645C21.1583 5.75592 21.1583 5.12276 20.7678 4.73223C20.3772 4.34171 19.7441 4.34171 19.3536 4.73223L17.6009 6.48492C16.3859 5.74831 14.8706 5.18804 13 5.03913V3C13 2.44772 12.5523 2 12 2Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 17C6.649 17 4.576 13.154 4.074 12C4.578 10.842 6.652 7 12 7C17.351 7 19.424 10.846 19.926 12C19.422 13.158 17.348 17 12 17Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 9C10.3477 9.01091 9.01091 10.3477 9 12C9 13.642 10.358 15 12 15C13.641 15 15 13.642 15 12C15 10.359 13.641 9 12 9V9Z"
      fill="currentColor"
    />
  </svg>
);
