import React from 'react';

export const Restart = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8 0.400059C14.1314 0.841887 14.0418 1.46869 13.6 1.80006L11.9465 3.04021C15.7336 3.36295 18.9734 5.60451 20.6896 8.7886C19.7755 8.37723 18.7803 8.11402 17.7346 8.02955C16.0859 6.17119 13.6797 5.00006 11 5.00006L12.8 7.40006C13.1314 7.84189 13.0418 8.46869 12.6 8.80006C12.1582 9.13143 11.5314 9.04189 11.2 8.60006L8.2 4.60006C7.86863 4.15823 7.95817 3.53143 8.4 3.20006L12.4 0.200059C12.8418 -0.131312 13.4686 -0.0417689 13.8 0.400059ZM10.2654 22.9705C5.63842 22.5967 2 18.7233 2 14.0001C2 13.4478 1.55228 13.0001 1 13.0001C0.447715 13.0001 0 13.4478 0 14.0001C0 20.0752 4.92487 25.0001 11 25.0001C11.559 25.0001 12.1082 24.9584 12.6448 24.8779C11.7464 24.3802 10.9421 23.7332 10.2654 22.9705ZM17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12ZM10 17C10 13.134 13.134 10 17 10C20.866 10 24 13.134 24 17C24 20.866 20.866 24 17 24C13.134 24 10 20.866 10 17ZM17 14C17.5523 14 18 14.4477 18 15V16.586L19 17.586C19.3905 17.9765 19.3905 18.6095 19 19C18.6095 19.3905 17.9765 19.3905 17.586 19L16.1754 17.5894C16.0631 17.4771 16 17.3248 16 17.1659V15C16 14.4477 16.4477 14 17 14Z"
      fill="currentColor"
    />
  </svg>
);
