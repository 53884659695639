import React from 'react';

export const Open = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 11.2929L20.5858 2H17C16.4477 2 16 1.55228 16 1C16 0.447715 16.4477 0 17 0H23C23.2751 0 23.5242 0.111056 23.705 0.290776L23.7114 0.297202C23.8051 0.392074 23.8761 0.501034 23.9241 0.617216C23.973 0.735123 24 0.86441 24 1V7C24 7.55228 23.5523 8 23 8C22.4477 8 22 7.55228 22 7V3.41421L12.7071 12.7071C12.3166 13.0976 11.6834 13.0976 11.2929 12.7071C10.9024 12.3166 10.9024 11.6834 11.2929 11.2929ZM5 2H12V4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V12H22V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2Z"
      fill="currentColor"
    />
  </svg>
);
