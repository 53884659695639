import React from 'react';

export const Dice = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75302 8.53382C1.32419 6.93343 2.27394 5.28842 3.87434 4.85959L15.4654 1.75376C17.0658 1.32494 18.7109 2.27468 19.1397 3.87508L22.2455 15.4662C22.6743 17.0666 21.7246 18.7116 20.1242 19.1404L8.53308 22.2463C6.93268 22.6751 5.28767 21.7253 4.85885 20.1249L1.75302 8.53382ZM4.39198 6.79144C3.85851 6.93438 3.54193 7.48272 3.68487 8.01619L6.7907 19.6073C6.93364 20.1408 7.48198 20.4573 8.01544 20.3144L19.6066 17.2086C20.14 17.0656 20.4566 16.5173 20.3137 15.9838L17.2078 4.39272C17.0649 3.85925 16.5166 3.54267 15.9831 3.68561L4.39198 6.79144ZM9.16155 9.13691C9.37596 9.93711 8.90109 10.7596 8.10089 10.974C7.30069 11.1884 6.47818 10.7136 6.26377 9.91336C6.04936 9.11316 6.52423 8.29066 7.32443 8.07625C8.12463 7.86183 8.94714 8.33671 9.16155 9.13691ZM14.8624 9.16229C15.6626 8.94788 16.1374 8.12537 15.923 7.32517C15.7086 6.52497 14.8861 6.0501 14.0859 6.26451C13.2857 6.47893 12.8108 7.30143 13.0253 8.10163C13.2397 8.90183 14.0622 9.3767 14.8624 9.16229ZM17.7348 14.0867C17.9492 14.8869 17.4743 15.7094 16.6741 15.9238C15.8739 16.1382 15.0514 15.6633 14.837 14.8631C14.6226 14.0629 15.0974 13.2404 15.8976 13.026C16.6978 12.8116 17.5203 13.2865 17.7348 14.0867ZM9.91262 17.7355C10.7128 17.5211 11.1877 16.6986 10.9733 15.8984C10.7589 15.0982 9.93636 14.6233 9.13616 14.8377C8.33596 15.0521 7.86109 15.8746 8.0755 16.6748C8.28992 17.475 9.11242 17.9499 9.91262 17.7355Z"
      fill="currentColor"
    />
  </svg>
);
