import React from 'react';

export const Union = () => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.11111V18.8889C0 19.5025 0.507411 20 1.13333 20H7.95556C8.56921 20 9.06667 19.5025 9.06667 18.8889C9.06667 18.2752 8.56921 17.7778 7.95556 17.7778H2.26667V2.22222H7.95556C8.56921 2.22222 9.06667 1.72476 9.06667 1.11111C9.06667 0.497462 8.56921 0 7.95556 0H1.13333C0.507411 0 0 0.497461 0 1.11111ZM4 10C4 9.44771 4.44772 9 5 9H13.2721L10.636 6.36396C10.2455 5.97343 10.2455 5.34027 10.636 4.94974C11.0266 4.55922 11.6597 4.55922 12.0503 4.94974L16.3934 9.29289C16.7839 9.68342 16.7839 10.3166 16.3934 10.7071L12.0503 15.0503C11.6597 15.4408 11.0266 15.4408 10.636 15.0503C10.2455 14.6597 10.2455 14.0266 10.636 13.636L13.2721 11H5C4.44771 11 4 10.5523 4 10Z"
      fill="currentColor"
    />
  </svg>
);
