import React from 'react';

export const PreviousRetro = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99629 1.99994C4.99629 1.44765 4.54858 0.999939 3.99629 0.999939C3.44401 0.999939 2.99629 1.44765 2.99629 1.99994L2.99629 5.98811C2.99601 6.01401 2.99673 6.03989 2.99844 6.06568C3.00397 6.15006 3.02014 6.23284 3.04619 6.31218C3.08872 6.44197 3.15807 6.56397 3.25295 6.66914C3.26467 6.68216 3.27676 6.6949 3.28921 6.70736C3.39198 6.81011 3.5148 6.88819 3.64848 6.93779C3.67145 6.94632 3.69464 6.95396 3.71799 6.96072C3.80767 6.98671 3.90132 7.00023 3.99637 7.00022L7.99637 6.99992C8.54865 6.99988 8.99633 6.55213 8.99629 5.99985C8.99625 5.44756 8.5485 4.99988 7.99622 4.99992L6.33936 5.00005C7.88552 3.74853 9.85335 2.99994 11.9963 2.99994C16.9669 2.99994 20.9963 7.02938 20.9963 11.9999C20.9963 16.9705 16.9669 20.9999 11.9963 20.9999C9.47843 20.9999 7.20355 19.9673 5.56896 18.2999C4.80732 17.523 4.18584 16.6092 3.74473 15.5996C3.52361 15.0935 2.93409 14.8625 2.42801 15.0836C1.92192 15.3047 1.69091 15.8942 1.91203 16.4003C2.45177 17.6356 3.21133 18.7519 4.14076 19.7C6.13601 21.7353 8.91937 22.9999 11.9963 22.9999C18.0714 22.9999 22.9963 18.0751 22.9963 11.9999C22.9963 5.92481 18.0714 0.999942 11.9963 0.999942C9.33649 0.999942 6.89749 1.94472 4.99629 3.51503L4.99629 1.99994ZM12.9963 8.00001C12.9963 7.44773 12.5486 7.00001 11.9963 7.00001C11.444 7.00001 10.9963 7.44773 10.9963 8.00001V12.0577C10.9963 12.2859 11.0869 12.5046 11.2482 12.6659L13.5823 15C13.9728 15.3905 14.6058 15.3905 14.9963 15C15.3868 14.6095 15.3868 13.9765 14.9963 13.586L12.9963 11.586V8.00001Z"
      fill="currentColor"
    />
  </svg>
);
