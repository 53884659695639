import React from 'react';

export const Burger = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99996 6.66666C3.26358 6.66666 2.66663 7.26361 2.66663 7.99999C2.66663 8.73637 3.26358 9.33332 3.99996 9.33332H28C28.7363 9.33332 29.3333 8.73637 29.3333 7.99999C29.3333 7.26361 28.7363 6.66666 28 6.66666H3.99996ZM2.66663 16C2.66663 15.2636 3.26358 14.6667 3.99996 14.6667H28C28.7363 14.6667 29.3333 15.2636 29.3333 16C29.3333 16.7364 28.7363 17.3333 28 17.3333H3.99996C3.26358 17.3333 2.66663 16.7364 2.66663 16ZM2.66663 24C2.66663 23.2636 3.26358 22.6667 3.99996 22.6667H28C28.7363 22.6667 29.3333 23.2636 29.3333 24C29.3333 24.7364 28.7363 25.3333 28 25.3333H3.99996C3.26358 25.3333 2.66663 24.7364 2.66663 24Z"
      fill="currentColor"
    />
  </svg>
);
