import classnames from 'classnames';
import React, { useState, useRef } from 'react';
import { usePopover } from 'react-use-popover';
import ReactDom from 'react-dom';

import styles from './ButtonLink.module.scss';
import { Tooltip } from '../Tooltip/Tooltip';

export interface ButtonProps {
  buttonColor?: 'primary' | 'light' | 'danger' | 'grey500';
  fontWeight?: 'bold' | 'normal';
  size?: 'normal' | 'small' | 'micro';
  tooltip?: React.ReactNode;
  tooltipPosition?: 'bottom-left';
  isDisabled?: boolean;
  'data-test'?: string;
  children?: React.ReactNode;
  className?: string;
  translate?: 'no';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

// -- Component
const ButtonFC: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonProps
> = (props, ref) => {
  const {
    buttonColor,
    fontWeight,
    isDisabled,
    children,
    className,
    translate,
    tooltip,
    size,
    tooltipPosition,
    onClick,
  } = props;
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
  };
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isMouseOver, setIsMouseOver] = useState<boolean | null>(null);

  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { dropdownStyle } = usePopover({
    anchorRef: ref ? (ref as React.RefObject<HTMLElement>) : buttonRef,
    dropdownElement: tooltipElement,
    position: tooltipPosition || 'bottom',
  });
  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const resolvedClassName = classnames(
    className,
    styles['button-link'],
    buttonColor && styles[`color-${buttonColor}`],
    fontWeight && styles[`font-weight-${fontWeight}`],
    size && styles[`size-${size}`],
    {
      [styles['is-disabled']]: isDisabled,
      [styles[`tooltip--${tooltipPosition}`]]: tooltipPosition,
    },
    'is-clickable',
  );

  return (
    <button
      className={resolvedClassName}
      onClick={handleOnClick}
      disabled={isDisabled}
      type="button"
      translate={translate}
      ref={ref || buttonRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-test={props['data-test']}
    >
      <span className={classnames(styles['content'], 'is-clickable')}>
        {children}
      </span>

      {tooltip &&
        isMouseOver &&
        ReactDom.createPortal(
          <div
            ref={setTooltipElement}
            style={{ ...dropdownStyle, zIndex: 999 }}
          >
            <Tooltip>{tooltip}</Tooltip>
          </div>,
          document.body,
        )}
    </button>
  );
};

export const ButtonLink = React.forwardRef(ButtonFC);
