import React from 'react';

export const Issues = () => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75862 2C5.10177 2 3.75862 3.34315 3.75862 5V19C3.75862 20.6569 5.10177 22 6.75862 22H18.7586C20.4155 22 21.7586 20.6569 21.7586 19V5C21.7586 3.34315 20.4155 2 18.7586 2H6.75862ZM5.75862 5C5.75862 4.44772 6.20634 4 6.75862 4H18.7586C19.3109 4 19.7586 4.44772 19.7586 5V19C19.7586 19.5523 19.3109 20 18.7586 20H6.75862C6.20634 20 5.75862 19.5523 5.75862 19V5ZM8.75862 7C8.20634 7 7.75862 7.44772 7.75862 8C7.75862 8.55228 8.20634 9 8.75862 9H16.7586C17.3109 9 17.7586 8.55228 17.7586 8C17.7586 7.44772 17.3109 7 16.7586 7H8.75862ZM8.75862 11C8.20634 11 7.75862 11.4477 7.75862 12C7.75862 12.5523 8.20634 13 8.75862 13H16.7586C17.3109 13 17.7586 12.5523 17.7586 12C17.7586 11.4477 17.3109 11 16.7586 11H8.75862ZM7.75862 16C7.75862 15.4477 8.20634 15 8.75862 15H12.7586C13.3109 15 13.7586 15.4477 13.7586 16C13.7586 16.5523 13.3109 17 12.7586 17H8.75862C8.20634 17 7.75862 16.5523 7.75862 16Z"
      fill="currentColor"
    />
  </svg>
);
