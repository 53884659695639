import React from 'react';

interface LogoWayProps {
  isLight?: boolean;
}
export const LogoWay = ({ isLight }: LogoWayProps) => {
  const color1 = isLight ? 'white' : '#3993FF';

  return (
    <svg
      width="172"
      height="40"
      viewBox="0 0 172 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3608 9.58824C22.9891 7.30866 25.6911 6.04685 28.4842 6.26171L31.4673 6.49117C32.4508 6.56683 33.2323 7.34833 33.308 8.33189L33.5375 11.315C33.7523 14.1081 32.4905 16.8101 30.2109 18.4384L26.459 21.1183L26.9047 23.347C27.2889 25.2679 26.6877 27.2536 25.3025 28.6388C24.9825 28.9588 24.4518 28.9148 24.1888 28.5466L21.4424 24.7017L20.4131 25.4369C19.6175 26.0051 18.5277 25.9149 17.8364 25.2236L14.5755 21.9628C13.8842 21.2715 13.794 20.1817 14.3623 19.3861L15.0975 18.3568L11.2526 15.6104C10.8843 15.3474 10.8404 14.8166 11.1604 14.4966C12.5455 13.1115 14.5313 12.5103 16.4521 12.8944L18.6808 13.3402L21.3608 9.58824ZM28.9336 14.4011C27.9573 15.3774 26.3744 15.3774 25.3981 14.4011C24.4218 13.4248 24.4218 11.8419 25.3981 10.8656C26.3744 9.88924 27.9573 9.88924 28.9336 10.8656C29.9099 11.8419 29.9099 13.4248 28.9336 14.4011ZM14.7915 25.0077C13.6199 23.8361 11.7204 23.8361 10.5488 25.0077C9.1538 26.4027 8.10272 28.1034 7.47884 29.9751L6.85967 31.8326C6.63163 32.5167 7.28247 33.1675 7.96659 32.9395L9.82411 32.3203C11.6958 31.6964 13.3964 30.6454 14.7915 29.2503C15.9631 28.0788 15.9631 26.1793 14.7915 25.0077Z"
        fill={color1}
      />
      <g clip-path="url(#clip0_6127_82426)">
        <path
          d="M81.3649 19.4664C81.3649 18.1949 81.6095 17.0672 82.0989 16.0833C82.6031 15.0993 83.2778 14.3425 84.1231 13.8127C84.9832 13.2829 85.9396 13.018 86.9925 13.018C87.9119 13.018 88.7127 13.2072 89.3948 13.5856C90.0918 13.964 90.6479 14.4409 91.0631 15.0161V13.2223H94.1994V25.8013H91.0631V23.9622C90.6627 24.5525 90.1066 25.0445 89.3948 25.438C88.6978 25.8165 87.8897 26.0057 86.9703 26.0057C85.9322 26.0057 84.9832 25.7332 84.1231 25.1883C83.2778 24.6433 82.6031 23.8789 82.0989 22.895C81.6095 21.8959 81.3649 20.7531 81.3649 19.4664ZM91.0631 19.5118C91.0631 18.7398 90.9148 18.0814 90.6182 17.5364C90.3216 16.9763 89.9212 16.5525 89.4171 16.2649C88.9129 15.9622 88.3716 15.8108 87.7933 15.8108C87.2149 15.8108 86.6811 15.9546 86.1917 16.2422C85.7024 16.5298 85.302 16.9536 84.9906 17.5137C84.694 18.0587 84.5457 18.7096 84.5457 19.4664C84.5457 20.2233 84.694 20.8893 84.9906 21.4645C85.302 22.0246 85.7024 22.456 86.1917 22.7588C86.6959 23.0615 87.2298 23.2129 87.7933 23.2129C88.3716 23.2129 88.9129 23.0691 89.4171 22.7815C89.9212 22.4787 90.3216 22.0549 90.6182 21.5099C90.9148 20.9499 91.0631 20.2838 91.0631 19.5118Z"
          fill={color1}
        />
        <path
          d="M101.079 13.018C101.999 13.018 102.807 13.2072 103.504 13.5856C104.201 13.9489 104.749 14.4257 105.15 15.0161V13.2223H108.286V25.8921C108.286 27.0577 108.056 28.0946 107.597 29.0028C107.137 29.9262 106.447 30.6528 105.528 31.1826C104.608 31.7275 103.496 32 102.191 32C100.441 32 99.0031 31.5837 97.8761 30.7512C96.7639 29.9186 96.1337 28.7833 95.9854 27.3453H99.0772C99.2403 27.9205 99.5888 28.3746 100.123 28.7077C100.671 29.0558 101.331 29.2299 102.102 29.2299C103.007 29.2299 103.741 28.9499 104.304 28.3898C104.868 27.8448 105.15 27.0123 105.15 25.8921V23.9395C104.749 24.5298 104.193 25.0218 103.481 25.4153C102.784 25.8089 101.984 26.0057 101.079 26.0057C100.041 26.0057 99.0921 25.7332 98.232 25.1883C97.3719 24.6433 96.6897 23.8789 96.1856 22.895C95.6962 21.8959 95.4515 20.7531 95.4515 19.4664C95.4515 18.1949 95.6962 17.0672 96.1856 16.0833C96.6897 15.0993 97.3645 14.3425 98.2097 13.8127C99.0698 13.2829 100.026 13.018 101.079 13.018ZM105.15 19.5118C105.15 18.7398 105.001 18.0814 104.705 17.5364C104.408 16.9763 104.008 16.5525 103.504 16.2649C103 15.9622 102.458 15.8108 101.88 15.8108C101.302 15.8108 100.768 15.9546 100.278 16.2422C99.789 16.5298 99.3886 16.9536 99.0772 17.5137C98.7806 18.0587 98.6324 18.7096 98.6324 19.4664C98.6324 20.2233 98.7806 20.8893 99.0772 21.4645C99.3886 22.0246 99.789 22.456 100.278 22.7588C100.783 23.0615 101.316 23.2129 101.88 23.2129C102.458 23.2129 103 23.0691 103.504 22.7815C104.008 22.4787 104.408 22.0549 104.705 21.5099C105.001 20.9499 105.15 20.2838 105.15 19.5118Z"
          fill={color1}
        />
        <path
          d="M111.918 11.7237C111.37 11.7237 110.91 11.5497 110.539 11.2015C110.183 10.8382 110.005 10.3917 110.005 9.86187C110.005 9.33207 110.183 8.89309 110.539 8.54494C110.91 8.18165 111.37 8 111.918 8C112.467 8 112.919 8.18165 113.275 8.54494C113.646 8.89309 113.831 9.33207 113.831 9.86187C113.831 10.3917 113.646 10.8382 113.275 11.2015C112.919 11.5497 112.467 11.7237 111.918 11.7237ZM113.453 13.2223V25.8013H110.339V13.2223H113.453Z"
          fill={color1}
        />
        <path
          d="M118.655 8.99905V25.8013H115.541V8.99905H118.655Z"
          fill={color1}
        />
        <path
          d="M132.199 19.2394C132.199 19.6935 132.169 20.1022 132.11 20.4655H123.101C123.175 21.3737 123.487 22.0851 124.035 22.5998C124.584 23.1145 125.259 23.3718 126.06 23.3718C127.216 23.3718 128.039 22.8647 128.529 21.8505H131.887C131.532 23.0615 130.849 24.0605 129.841 24.8477C128.833 25.6197 127.594 26.0057 126.126 26.0057C124.94 26.0057 123.872 25.7408 122.923 25.211C121.989 24.666 121.255 23.9016 120.721 22.9177C120.202 21.9338 119.943 20.7985 119.943 19.5118C119.943 18.21 120.202 17.0672 120.721 16.0833C121.24 15.0993 121.967 14.3425 122.901 13.8127C123.835 13.2829 124.91 13.018 126.126 13.018C127.298 13.018 128.343 13.2753 129.263 13.79C130.197 14.3046 130.916 15.0388 131.42 15.9924C131.939 16.9309 132.199 18.0132 132.199 19.2394ZM128.974 18.3311C128.959 17.5137 128.67 16.8628 128.106 16.3784C127.543 15.8789 126.853 15.6291 126.037 15.6291C125.266 15.6291 124.614 15.8713 124.08 16.3557C123.561 16.825 123.242 17.4834 123.123 18.3311H128.974Z"
          fill={color1}
        />
        <path
          d="M146.481 13.2219L138.851 31.7498H135.537L138.206 25.483L133.268 13.2219H136.76L139.941 22.0091L143.166 13.2219H146.481Z"
          fill={color1}
        />
        <path
          d="M152.643 26.0053C151.456 26.0053 150.389 25.7404 149.439 25.2106C148.49 24.6656 147.742 23.9012 147.193 22.9173C146.659 21.9334 146.392 20.7981 146.392 19.5114C146.392 18.2248 146.666 17.0895 147.215 16.1056C147.779 15.1216 148.542 14.3648 149.506 13.835C150.47 13.29 151.545 13.0176 152.732 13.0176C153.918 13.0176 154.993 13.29 155.957 13.835C156.921 14.3648 157.677 15.1216 158.226 16.1056C158.789 17.0895 159.071 18.2248 159.071 19.5114C159.071 20.7981 158.782 21.9334 158.203 22.9173C157.64 23.9012 156.869 24.6656 155.89 25.2106C154.926 25.7404 153.844 26.0053 152.643 26.0053ZM152.643 23.2352C153.206 23.2352 153.732 23.0989 154.222 22.8265C154.726 22.5389 155.126 22.115 155.423 21.5549C155.72 20.9949 155.868 20.3137 155.868 19.5114C155.868 18.3156 155.556 17.3998 154.934 16.764C154.326 16.1131 153.577 15.7877 152.687 15.7877C151.797 15.7877 151.048 16.1131 150.44 16.764C149.847 17.3998 149.551 18.3156 149.551 19.5114C149.551 20.7073 149.84 21.6306 150.418 22.2815C151.011 22.9173 151.753 23.2352 152.643 23.2352Z"
          fill={color1}
        />
        <path
          d="M172 13.2219V25.8009H168.864V24.2115C168.463 24.7565 167.937 25.1879 167.284 25.5058C166.647 25.8085 165.95 25.9599 165.194 25.9599C164.23 25.9599 163.377 25.7555 162.635 25.3468C161.894 24.923 161.308 24.3099 160.878 23.5076C160.463 22.6902 160.255 21.7215 160.255 20.6013V13.2219H163.37V20.1472C163.37 21.1462 163.614 21.9182 164.104 22.4632C164.593 22.993 165.26 23.2579 166.105 23.2579C166.966 23.2579 167.64 22.993 168.13 22.4632C168.619 21.9182 168.864 21.1462 168.864 20.1472V13.2219H172Z"
          fill={color1}
        />
        <path
          d="M66.4622 13.2219L62.8587 25.8009H59.4999L57.2533 17.0138L55.0067 25.8009H51.6257L48 13.2219H51.1586L53.3385 22.8038L55.6963 13.2219H58.9883L61.3017 22.7811L63.4815 13.2219H66.4622Z"
          fill={color1}
        />
        <path
          d="M78.8611 19.239C78.8611 19.6931 78.8315 20.1018 78.7722 20.4651H69.7635C69.8377 21.3733 70.1491 22.0847 70.6977 22.5994C71.2464 23.1141 71.9211 23.3714 72.7219 23.3714C73.8786 23.3714 74.7016 22.8643 75.191 21.8501H78.5497C78.1938 23.0611 77.5117 24.0602 76.5033 24.8473C75.4949 25.6193 74.2567 26.0053 72.7886 26.0053C71.6023 26.0053 70.5346 25.7404 69.5856 25.2106C68.6513 24.6656 67.9173 23.9012 67.3834 22.9173C66.8644 21.9334 66.6049 20.7981 66.6049 19.5114C66.6049 18.2096 66.8644 17.0668 67.3834 16.0829C67.9025 15.0989 68.6291 14.3421 69.5633 13.8123C70.4976 13.2825 71.5727 13.0176 72.7886 13.0176C73.9601 13.0176 75.0056 13.2749 75.925 13.7896C76.8592 14.3042 77.5784 15.0384 78.0826 15.992C78.6016 16.9305 78.8611 18.0128 78.8611 19.239ZM75.6358 18.3307C75.621 17.5133 75.3318 16.8624 74.7683 16.378C74.2048 15.8785 73.5153 15.6287 72.6997 15.6287C71.9286 15.6287 71.2761 15.8709 70.7422 16.3553C70.2232 16.8246 69.9044 17.483 69.7858 18.3307H75.6358Z"
          fill={color1}
        />
      </g>
      <defs>
        <clipPath id="clip0_6127_82426">
          <rect
            width="124"
            height="24"
            fill="white"
            transform="translate(48 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
