import React from 'react';

export const Robot = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 6.82929C14.1652 6.41746 15 5.30622 15 4C15 2.34315 13.6569 1 12 1C10.3431 1 9 2.34315 9 4C9 5.30622 9.83481 6.41746 11 6.82929V7V8V9H6C3.79086 9 2 10.7909 2 13H1C0.447715 13 0 13.4477 0 14V18C0 18.5523 0.447715 19 1 19H2C2 21.2091 3.79086 23 6 23H18C20.2091 23 22 21.2091 22 19H23C23.5523 19 24 18.5523 24 18V14C24 13.4477 23.5523 13 23 13H22C22 10.7909 20.2091 9 18 9H13V8V7V6.82929ZM12 21C10.6412 21 9.43064 20.3127 8.64821 19.2414C8.24259 18.6861 8.71776 18 9.40547 18H14.5945C15.2822 18 15.7574 18.6861 15.3518 19.2414C14.5694 20.3127 13.3588 21 12 21ZM10 14C10 15.6569 8.65685 17 7 17C5.34315 17 4 15.6569 4 14C4 12.3431 5.34315 11 7 11C8.65685 11 10 12.3431 10 14ZM17 17C18.6569 17 20 15.6569 20 14C20 12.3431 18.6569 11 17 11C15.3431 11 14 12.3431 14 14C14 15.6569 15.3431 17 17 17ZM6 14C6 13.4477 6.44772 13 7 13C7.55228 13 8 13.4477 8 14C8 14.5523 7.55228 15 7 15C6.44772 15 6 14.5523 6 14ZM17 13C16.4477 13 16 13.4477 16 14C16 14.5523 16.4477 15 17 15C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13Z"
      fill="currentColor"
    />
  </svg>
);
