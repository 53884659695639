import React from 'react';

export const Pencil = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5335 2.30863C18.3362 2.11104 18.0685 2 17.7893 2C17.5101 2 17.2423 2.11104 17.0451 2.30863L3.36096 15.9929C3.22629 16.1277 3.13063 16.2965 3.08412 16.4813L2.03149 20.6919C1.94178 21.0505 2.04674 21.4299 2.30802 21.6914C2.5693 21.9529 2.9486 22.0581 3.30728 21.9687L7.51778 20.9161C7.70304 20.8698 7.87251 20.774 8.00725 20.6392L21.6914 6.95497C21.889 6.75771 22 6.48996 22 6.21076C22 5.93155 21.889 5.6638 21.6914 5.46654L18.5335 2.30863ZM6.7241 18.9445L4.49885 19.5003L5.05569 17.275L14.6314 7.69918L16.3009 9.36866L6.7241 18.9445ZM17.7893 7.88024L16.1198 6.21076L17.7893 4.54128L19.4588 6.21076L17.7893 7.88024Z"
      fill="currentColor"
    />
  </svg>
);
