import React from 'react';

export const ManageFacilitators = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 6C7 4.34315 8.34315 3 10 3C11.6569 3 13 4.34315 13 6C13 7.65685 11.6569 9 10 9C8.34315 9 7 7.65685 7 6ZM13.4013 9.6649C14.3847 8.75182 15 7.44777 15 6C15 3.23858 12.7614 1 10 1C7.23858 1 5 3.23858 5 6C5 7.44777 5.61532 8.75182 6.59871 9.6649C3.31433 11.0065 1 14.233 1 18C1 18.5523 1.44772 19 2 19C2.55228 19 3 18.5523 3 18C3 14.134 6.13401 11 10 11C11.4872 11 12.8662 11.4638 14 12.2547C14.6036 11.8337 15.2766 11.5053 15.9985 11.2903C15.238 10.6099 14.361 10.0569 13.4013 9.6649ZM12.768 19.3188C12.7316 19.4532 12.7509 19.5965 12.8217 19.7173L13.8941 21.5338C14.0421 21.7842 14.3708 21.8703 14.6266 21.7259L15.3752 21.3028C15.6862 21.5427 16.0299 21.7396 16.3913 21.8866V22.725C16.3913 23.0149 16.6314 23.25 16.9275 23.25H19.0725C19.3686 23.25 19.6087 23.0149 19.6087 22.725V21.8861C19.9729 21.7376 20.3148 21.5412 20.6248 21.3022L21.3734 21.7254C21.63 21.8694 21.9572 21.7836 22.1059 21.5332L23.1783 19.7168C23.2491 19.5965 23.2684 19.4532 23.232 19.3183C23.1955 19.1833 23.1054 19.0689 22.9821 18.9996L22.2469 18.5838C22.3044 18.1966 22.304 17.8033 22.2458 17.4162L22.981 17.0004C23.237 16.855 23.3247 16.5344 23.1773 16.2832L22.1048 14.4668C21.9565 14.2159 21.6288 14.1299 21.3723 14.2746L20.6238 14.6978C20.3141 14.4585 19.9724 14.2621 19.6081 14.1139V13.275C19.6081 12.9851 19.3681 12.75 19.0719 12.75H16.927C16.6309 12.75 16.3908 12.9851 16.3908 13.275V14.1139C16.0265 14.2624 15.6847 14.4588 15.3746 14.6978L14.6266 14.2746C14.3701 14.1296 14.0422 14.2157 13.8941 14.4668L12.8217 16.2832C12.7509 16.4035 12.7316 16.5468 12.768 16.6817C12.8045 16.8167 12.8946 16.9306 13.0179 17.0004L13.7531 17.4162C13.6952 17.8033 13.6952 18.1967 13.7531 18.5838L13.0179 18.9996C12.8946 19.0693 12.8048 19.1841 12.768 19.3188ZM18 19.75C18.9651 19.75 19.75 18.9651 19.75 18C19.75 17.0349 18.9651 16.25 18 16.25C17.0349 16.25 16.25 17.0349 16.25 18C16.25 18.9651 17.0349 19.75 18 19.75Z"
      fill="currentColor"
    />
  </svg>
);
