import React from 'react';

export const NotificationBell = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0823 2.08718L12.0704 2.00308C12.0169 1.99897 11.9631 1.99897 11.9096 2.00308L10.8478 2.09231C6.94298 2.66872 3.99817 6.15692 3.99817 10.2051V14.1415L3.05119 17.0605C2.94978 17.3731 3.00083 17.7167 3.18841 17.9841C3.37599 18.2515 3.67725 18.41 3.99817 18.4103H8.49331C8.49331 20.3897 10.0616 22 11.9895 22C13.9174 22 15.4857 20.3897 15.4857 18.4103H19.9809C20.3403 18.4177 20.6759 18.2263 20.8597 17.909C21.0434 17.5918 21.0469 17.1976 20.8689 16.8769L19.9809 14.1415V10.2051C19.9809 6.1641 17.0421 2.67692 13.0823 2.08718ZM11.9895 19.9487C11.1494 19.9487 10.4911 19.2728 10.4911 18.4103H13.4879C13.4879 19.2728 12.8296 19.9487 11.9895 19.9487ZM5.38467 16.359L5.94506 14.6318C5.97839 14.5271 5.99558 14.4178 5.99601 14.3077V10.2051C5.99601 7.17026 8.20462 4.55487 11.0705 4.13026L11.9905 4.05436L12.8576 4.1241C15.7794 4.56102 17.983 7.17538 17.983 10.2051V14.3077C17.983 14.4174 18.001 14.5272 18.035 14.6318L18.5944 16.359H5.38467Z"
      fill="currentColor"
    />
  </svg>
);
