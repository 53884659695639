import React from 'react';

export const Eye2 = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 17C9.07898 17 6.02598 15.4231 3.26641 12C6.02598 8.57692 9.07898 7 12 7C14.921 7 17.974 8.57692 20.7336 12C17.974 15.4231 14.921 17 12 17ZM22.8 11.4C19.6794 7.23922 15.9134 5 12 5C8.08661 5 4.32058 7.23922 1.2 11.4L0.75 12L1.2 12.6C4.32058 16.7608 8.08661 19 12 19C15.9134 19 19.6794 16.7608 22.8 12.6L23.25 12L22.8 11.4ZM12 8C9.79691 8.01454 8.01454 9.79691 8 12C8 14.1893 9.81067 16 12 16C14.188 16 16 14.1893 16 12C16 11.5914 15.9368 11.1959 15.8198 10.8229C15.5026 11.5137 14.8018 12 14 12C12.9053 12 12 11.0947 12 10C12.0053 9.19139 12.487 8.49613 13.1785 8.18066C12.8051 8.06335 12.4091 8 12 8Z"
      fill="currentColor"
    />
  </svg>
);
