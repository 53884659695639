import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { GoogleAuthProvider } from 'firebase/auth';
import { linkWithPopup, signInWithPopup } from '../../data/auth';

import styles from './GoogleSigninButton.module.scss';
import { InlineAlert } from '@we-agile-you/react-base';
import { USER_SIGNED_IN } from '../../constants';
import { Spinner } from '@we-agile-you/react-base';

const googleProvider = new GoogleAuthProvider();
type GoogleSigninButtonProps =
  | {
      onOpenMailInUseAlert: () => void;
      onSignedIn: () => void;
      mode: 'sign-up';
    }
  | {
      onSignedIn: () => void;
      mode: 'sign-in';
    };
export const GoogleSigninButton = (props: GoogleSigninButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const { mode, onSignedIn } = props;

  const handleSiginWithGoogle = () => {
    setIsLoading(true);

    if (mode === 'sign-up') {
      linkWithPopup(googleProvider, true)
        .then((userCredential) => {
          setIsLoading(false);

          if (!userCredential?.user) return;

          dispatch({
            type: USER_SIGNED_IN,
            uid: userCredential.user.uid,
            email: userCredential.user.email,
            isAnonymous: userCredential.user.isAnonymous,
          });
          setIsLoading(false);
          onSignedIn();
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e?.code);
          console.log(e);
          if (
            e.code === 'auth/credential-already-in-use' ||
            e.code === 'auth/email-already-in-use'
          ) {
            props.onOpenMailInUseAlert();
          } else if (e.code === 'auth/popup-closed-by-user') {
            // do nothing
          } else {
            const message =
              e.code === 'auth/popup-blocked'
                ? 'Popup blocked by browser. Please allow popups for this site at your browser top bar.'
                : e?.message || 'Unknown error signing up, please try again.';

            setError(message);
          }
        });
    } else {
      signInWithPopup(googleProvider)
        .then(() => {
          setIsLoading(false);
          onSignedIn();
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e?.message || 'Unknown error signing up, please try again.');
        });
    }
  };

  return (
    <>
      {error && <InlineAlert title="Error" content={error} />}
      <button
        onClick={handleSiginWithGoogle}
        className={cx(
          styles['button'],
          isLoading && styles['button--is-loading'],
        )}
        disabled={isLoading}
      >
        {isLoading && (
          <span className={styles['spinner-wrapper']}>
            <Spinner className={styles['spinner']} size="medium" />
          </span>
        )}
        <div className={styles['logo']}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 48 48"
          >
            <g>
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </g>
          </svg>
        </div>
        <div className={styles['text']}>{`${
          mode === 'sign-in' ? 'Login' : 'Sign up'
        } with Google`}</div>
      </button>
    </>
  );
};
