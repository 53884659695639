import React from 'react';

export const Hilfe = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM19.411 9H16V9.031C15.7082 8.63915 15.3609 8.29184 14.969 8H15V4.589C17.0041 5.40567 18.5943 6.99588 19.411 9ZM12 15C10.346 15 9 13.654 9 12C9 10.346 10.346 9 12 9C13.654 9 15 10.346 15 12C15 13.654 13.654 15 12 15ZM13 4.069V7.101C12.3402 6.96598 11.6598 6.96598 11 7.101V4.069C11.328 4.028 11.66 4 12 4C12.34 4 12.672 4.028 13 4.069ZM9 4.589V8H9.031C8.63915 8.29184 8.29184 8.63915 8 9.031V9H4.589C5.402 7 6.999 5.402 9 4.589ZM4.069 11H7.101C6.9665 11.6599 6.96684 12.3402 7.102 13H4.069C4.028 12.672 4 12.339 4 12C4 11.661 4.028 11.328 4.069 11ZM4.589 15H8V14.969C8.284 15.35 8.621 15.687 9 15.974V19.411C6.99588 18.5943 5.40567 17.0041 4.589 15ZM11 19.931V16.899C11.6598 17.034 12.3402 17.034 13 16.899V19.931C12.672 19.972 12.34 20 12 20C11.66 20 11.328 19.972 11 19.931ZM15 19.411V15.974C15.379 15.6878 15.7157 15.3495 16 14.969V15H19.411C18.5943 17.0041 17.0041 18.5943 15 19.411ZM19.931 13H16.899C17.0335 12.3401 17.0332 11.6598 16.898 11H19.93C19.972 11.328 20 11.661 20 12C20 12.339 19.972 12.672 19.931 13Z"
      fill="currentColor"
    />
  </svg>
);
