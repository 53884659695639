import React from 'react';

export const Back = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.82893 4.04338C9.43246 3.63514 8.7775 3.63381 8.37936 4.04042L1.45119 11.1161C1.40667 11.1616 1.36729 11.2102 1.33305 11.2612C1.3166 11.2761 1.30052 11.2916 1.28485 11.3077C0.902245 11.7017 0.903545 12.3289 1.28778 12.7213L8.23861 19.8197C8.63676 20.2263 9.29173 20.225 9.68818 19.8167C10.0708 19.4227 10.0695 18.7956 9.68525 18.4032L4.39491 13.0005H22C22.5523 13.0005 23 12.5528 23 12.0005C23 11.4482 22.5523 11.0005 22 11.0005H4.39806L9.82604 5.45694C10.2103 5.06454 10.2115 4.43735 9.82893 4.04338Z"
      fill="currentColor"
    />
  </svg>
);
