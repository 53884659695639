import React from 'react';

export const Add = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 0.8C19 0.358172 19.4477 0 20 0C20.5523 0 21 0.358172 21 0.8V3H23.2C23.6418 3 24 3.44772 24 4C24 4.55229 23.6418 5 23.2 5H21V7.2C21 7.64183 20.5523 8 20 8C19.4477 8 19 7.64183 19 7.2V5H16.8C16.3582 5 16 4.55228 16 4C16 3.44772 16.3582 3 16.8 3H19V0.8ZM12 1C5.93372 1 1 5.93372 1 12C1 18.0663 5.93372 23 12 23C18.0663 23 23 18.0663 23 12C23 11.4477 22.5523 11 22 11C21.4477 11 21 11.4477 21 12C21 16.9617 16.9617 21 12 21C7.03828 21 3 16.9617 3 12C3 7.03828 7.03828 3 12 3C12.5523 3 13 2.55228 13 2C13 1.44772 12.5523 1 12 1ZM12 7C12.5523 7 13 7.44772 13 8V11.586L15 13.586C15.3905 13.9765 15.3905 14.6095 15 15C14.6095 15.3905 13.9765 15.3905 13.586 15L11.2519 12.6659C11.0906 12.5046 11 12.2858 11 12.0577V8C11 7.44772 11.4477 7 12 7Z"
      fill="currentColor"
    />
  </svg>
);
