import React from 'react';

export const List = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 20C6 21.1046 5.10457 22 4 22C2.89543 22 2 21.1046 2 20C2 18.8954 2.89543 18 4 18C5.10457 18 6 18.8954 6 20ZM6 12C6 13.1046 5.10457 14 4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12ZM6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4ZM10 3C9.44772 3 9 3.44772 9 4C9 4.55228 9.44772 5 10 5H21C21.5523 5 22 4.55228 22 4C22 3.44772 21.5523 3 21 3H10ZM9 12C9 11.4477 9.44772 11 10 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H10C9.44772 13 9 12.5523 9 12ZM10 19C9.44772 19 9 19.4477 9 20C9 20.5523 9.44772 21 10 21H21C21.5523 21 22 20.5523 22 20C22 19.4477 21.5523 19 21 19H10Z"
      fill="currentColor"
    />
  </svg>
);
