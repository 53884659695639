import React from 'react';

export const RetroBoard = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 7C2 5.34315 3.34315 4 5 4H8H10H14H16H19C20.6569 4 22 5.34315 22 7V17C22 18.6569 20.6569 20 19 20H16H14H10H8H5C3.34315 20 2 18.6569 2 17V7ZM16 18H19C19.5523 18 20 17.5523 20 17V7C20 6.44772 19.5523 6 19 6H16V18ZM14 6V18H10V6H14ZM5 6H8V18H5C4.44772 18 4 17.5523 4 17V7C4 6.44772 4.44772 6 5 6Z"
      fill="currentColor"
    />
  </svg>
);
