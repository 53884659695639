import React from 'react';

export const User = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 13.6992 19.4702 15.2747 18.5668 16.5703C17.929 14.8471 16.6323 13.4435 14.9826 12.6654C15.6153 11.9579 16 11.0239 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 11.0239 8.38468 11.9579 9.01739 12.6654C7.36767 13.4435 6.07097 14.8471 5.43317 16.5703C4.52978 15.2747 4 13.6992 4 12ZM7.05065 18.2856C8.41234 19.3593 10.1313 20 12 20C13.8687 20 15.5877 19.3593 16.9494 18.2856C16.6027 15.8626 14.5189 14 12 14C9.48112 14 7.39727 15.8626 7.05065 18.2856ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8Z"
      fill="currentColor"
    />
  </svg>
);
