import React from 'react';

export const Copy = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 4.5V3.5C14 2.39543 13.1046 1.5 12 1.5H4C2.89543 1.5 2 2.39543 2 3.5V13.5C2 14.6046 2.89543 15.5 4 15.5H6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M10 10.5C10 9.39543 10.8954 8.5 12 8.5H20C21.1046 8.5 22 9.39543 22 10.5V20.5C22 21.6046 21.1046 22.5 20 22.5H12C10.8954 22.5 10 21.6046 10 20.5V10.5Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
