import React from 'react';

export const CheckSolid = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM17.7804 9.62537C18.1257 9.19439 18.0563 8.56504 17.6253 8.21968C17.1944 7.87432 16.565 7.94372 16.2196 8.3747L11.0923 14.5168L7.69628 11.2823C7.29987 10.8977 6.66678 10.9073 6.28223 11.3038C5.89769 11.7002 5.90731 12.3333 6.30372 12.7178L10.4889 16.7178C10.6911 16.9139 10.9666 17.0157 11.2477 16.9981C11.5288 16.9805 11.7894 16.8452 11.9655 16.6254L17.7804 9.62537Z"
      fill="currentColor"
    />
  </svg>
);
