import React from 'react';

export const Camera = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.23832 2.4453C8.42378 2.1671 8.73601 2 9.07037 2H12.9296C13.264 2 13.5762 2.1671 13.7617 2.4453L14.5742 3.6641C15.1306 4.4987 16.0673 5 17.0704 5H19C19.5523 5 20 5.44772 20 6V17C20 17.5523 19.5523 18 19 18H3C2.44772 18 2 17.5523 2 17V6C2 5.44772 2.44772 5 3 5H4.92963C5.93269 5 6.86939 4.4987 7.42578 3.6641L8.23832 2.4453ZM9.07037 0C8.06731 0 7.13061 0.501303 6.57422 1.3359L5.76168 2.5547C5.57622 2.8329 5.26399 3 4.92963 3H3C1.34315 3 0 4.34315 0 6V17C0 18.6569 1.34315 20 3 20H19C20.6569 20 22 18.6569 22 17V6C22 4.34315 20.6569 3 19 3H17.0704C16.736 3 16.4238 2.8329 16.2383 2.5547L15.4258 1.3359C14.8694 0.501303 13.9327 0 12.9296 0H9.07037ZM8 11C8 9.34315 9.34315 8 11 8C12.6569 8 14 9.34315 14 11C14 12.6569 12.6569 14 11 14C9.34315 14 8 12.6569 8 11ZM11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6Z"
      fill="currentColor"
    />
  </svg>
);
