import React from 'react';

export const Top = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.957 9.82893C20.3652 9.43246 20.3666 8.7775 19.9599 8.37936L12.8843 1.45119C12.8388 1.40667 12.7902 1.36729 12.7391 1.33305C12.7243 1.3166 12.7088 1.30052 12.6926 1.28485C12.2986 0.902244 11.6715 0.903545 11.2791 1.28778L4.18065 8.23861C3.77404 8.63676 3.7754 9.29172 4.18365 9.68818C4.57763 10.0708 5.20482 10.0695 5.59721 9.68525L10.9999 4.39491L10.9999 22C10.9999 22.5523 11.4476 23 11.9999 23C12.5522 23 12.9999 22.5523 12.9999 22L12.9999 4.39806L18.5434 9.82604C18.9358 10.2103 19.563 10.2115 19.957 9.82893Z"
      fill="currentColor"
    />
  </svg>
);
