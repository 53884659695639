import React from 'react';

export const Comment = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.486 2 2 5.54082 2 9.89422C2 12.7804 3.982 15.5296 7 16.9232V20.9461C6.99911 21.3723 7.24267 21.7569 7.61673 21.9199C7.99078 22.083 8.42136 21.9923 8.707 21.6903L12.421 17.7811C17.74 17.6064 22 14.135 22 9.89422C22 5.54082 17.514 2 12 2ZM12 15.6833C11.7347 15.6829 11.4802 15.7939 11.293 15.9917L9 18.4052V16.2096C8.99979 15.7652 8.73448 15.3689 8.337 15.2191C5.743 14.2403 4 12.1004 4 9.89422C4 6.70285 7.589 4.10513 12 4.10513C16.411 4.10513 20 6.70285 20 9.89422C20 13.0856 16.411 15.6833 12 15.6833ZM8 8C8 7.44772 8.44772 7 9 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H9C8.44772 9 8 8.55228 8 8ZM9 10C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12H13C13.5523 12 14 11.5523 14 11C14 10.4477 13.5523 10 13 10H9Z"
      fill="currentColor"
    />
  </svg>
);
