import {
  Button,
  Container,
  FlexBox,
  Header1,
  Paragraph,
  VerticalSpacing,
  Icon,
  Header4,
  Header2,
} from '@we-agile-you/react-base';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Header } from '../../components/Header/Header';

// logos
import microsoft from './logos/microsoft.svg';
import deloitte from './logos/deloitte.svg';
import google from './logos/google.svg';
import hpe from './logos/hpe.svg';
import figma from './logos/figma.svg';

// screens
import ppo from './screens/ppo.png';
import retro from './screens/retro.png';

// way logos
import wayPpo from './product-logos/way-ppo.svg';
import wayRetro from './product-logos/way-retro.svg';

import styles from './LandingTemplate.module.scss';
import { Wave } from '../../components/Wave/Wave';
import { Footer } from '../../components/Footer/Footer';
import { LogosSection } from '../../components/LogosSection/LogosSection';

type Post = {
  author: number;
  jetpack_featured_media_url: string;
  excerpt: {
    rendered: string;
  };
  title: {
    rendered: string;
  };
  guid: {
    rendered: string;
  };
};
const authors = [
  {
    id: 218425933,
    name: 'Miquel Las Heras',
  },
  {
    id: 218425934,
    name: 'Jörg Stroisch',
  },
];
export default function LandingTemplate() {
  const [posts, setPosts] = useState<Post[]>([]);
  useEffect(() => {
    fetch('https://blog.weagileyou.com/wp-json/wp/v2/posts')
      .then((response) => response.json())
      .then((posts) => setPosts(posts.slice(0, 3)));
  }, []);

  return (
    <>
      <Header />
      <VerticalSpacing spacing="spacing-xxl-4" />
      <VerticalSpacing spacing="spacing-l" />
      <VerticalSpacing spacing="spacing-xxl-4" />
      <Container className={styles['header-container']}>
        <Header1 align="center">
          Hi! We make tools for agile development teams
        </Header1>
      </Container>
      <VerticalSpacing spacing="spacing-xxl" />
      <Container className={styles['headline-container']}>
        <Paragraph align="center" color="grey600">
          Have fun while being productive
        </Paragraph>
      </Container>
      <VerticalSpacing spacing="spacing-xxl" />
      <Container className={styles['ctas-container']}>
        <FlexBox className={styles['ctas-flexbox']}>
          <a href="https://planningpokeronline.com/" target="_blank">
            <Button icon={<Icon icon="ppo" />} isBlock>
              Planning Poker Online
            </Button>
          </a>
          <a href="https://retrospectiveonline.com/" target="_blank">
            <Button icon={<Icon icon="retro" />} isBlock>
              Retrospective Online{' '}
              <span className={styles['beta-label']}>Beta</span>
            </Button>
          </a>
        </FlexBox>
      </Container>
      <VerticalSpacing spacing="spacing-xxl" />
      <VerticalSpacing spacing="spacing-xxl" />
      <div className={styles['wave-container']}>
        <Wave color="grey100" />
      </div>
      <div className={styles['grey-background']}>
        <Container size="medium">
          <Paragraph align="center" color="grey600">
            Trusted by teams at:
          </Paragraph>
          <VerticalSpacing spacing="spacing-l" />
          <ul className={styles['logos-list']}>
            <li key="logo-Microsoft">
              <img src={microsoft} alt="Microsoft" />
            </li>
            <li key="logo-HP">
              <img src={hpe} alt="HP" />
            </li>
            <li
              key="logo-Google"
              className={cx(
                styles['first-section__logos-item'],
                styles['google'],
              )}
            >
              <img src={google} alt="Google" />
            </li>
            <li key="logo-Deloitte">
              <img src={deloitte} alt="Deloitte" />
            </li>
            <li
              key="logo-Figma"
              className={cx(
                styles['first-section__logos-item'],
                styles['figma'],
              )}
            >
              <img src={figma} alt="Figma" />
            </li>
          </ul>
        </Container>
        <VerticalSpacing spacing="spacing-xxl" />
        <VerticalSpacing spacing="spacing-xxl" />
        <Container className={styles['headline-container']}>
          <Paragraph align="center" color="grey600">
            Without the right tools, some steps in the agile process can be
            tedious to say the least. As agile experts, the team at We Agile You
            is on a mission to provide the best collaboration tools for
            development teams – throughout the various stages of the agile
            cycle. Our goal is to keep all team members excited, engaged, and
            eager to tackle the agile process with ease.
          </Paragraph>
        </Container>
        <VerticalSpacing spacing="spacing-xxl" />
        <VerticalSpacing spacing="spacing-xxl" />
        <Container size="big">
          <FlexBox className={styles['product-screens-flexbox']}>
            <div className={styles['product-screen']}>
              <img src={ppo} alt="Planning poker game" />
            </div>
            <div
              className={cx(
                styles['hidden-small-screen'],
                styles['product-screen'],
              )}
            >
              <img src={retro} alt="Retrospective game" />
            </div>
          </FlexBox>
        </Container>
        <div className={styles['products-wrapper']}>
          <Container size="big">
            <FlexBox justifyContent="space-evenly" alignItems="strech">
              <div className={styles['product-card']}>
                <div>
                  <img src={wayPpo} alt="Planning poker online" />
                </div>
                <VerticalSpacing spacing="spacing-xxl" />
                <Paragraph size="micro">
                  Our online scrum planning poker for agile development teams
                  takes the in-person game of scrum poker and makes it available
                  for remote teams worldwide. Planning Poker Online is designed
                  to make the project development process more efficient,
                  engaging, and intuitive - while injecting a bit of fun into
                  the otherwise boring process.
                </Paragraph>
                <VerticalSpacing spacing="spacing-l" />
                <a href="https://planningpokeronline.com/" target="_blank">
                  <Button isBlock>Try for free</Button>
                </a>
              </div>
              <div
                className={cx(
                  styles['product-card'],
                  styles['hidden-small-screen'],
                )}
              >
                <div>
                  <div>
                    <img src={wayRetro} alt="Retrospective online" />
                  </div>
                  <VerticalSpacing spacing="spacing-xxl" />
                  <Paragraph size="micro">
                    Our retro app is simple and intuitive, almost like if you
                    were pasting sticky notes on a wall. Make sure you run your
                    meeting with ease using our simple but complete tool.
                  </Paragraph>
                  <VerticalSpacing spacing="spacing-l" />
                </div>
                <div>
                  <a href="https://retrospectiveonline.com/" target="_blank">
                    <Button isBlock>Try for free</Button>
                  </a>
                </div>
              </div>
            </FlexBox>
          </Container>
          <Container size="big" className={styles['hidden-big-screen']}>
            <VerticalSpacing spacing="spacing-xxl-4" />
            <FlexBox className={styles['product-screens-flexbox']}>
              <div className={styles['product-screen']}>
                <img src={retro} alt="Retrospective game" />
              </div>
            </FlexBox>
          </Container>
          <Container size="big" className={styles['hidden-big-screen']}>
            <FlexBox justifyContent="space-evenly">
              <div className={cx(styles['product-card'])}>
                <div>
                  <img src={wayRetro} alt="Retrospective online" />
                </div>
                <VerticalSpacing spacing="spacing-xxl" />
                <Paragraph size="micro">
                  Our retro app is simple and intuitive, almost like if you were
                  pasting sticky notes on a wall. Make sure you run your meeting
                  with ease using our simple but complete tool.
                </Paragraph>
                <VerticalSpacing spacing="spacing-l" />
                <a href="https://retrospectiveonline.com/" target="_blank">
                  <Button isBlock>Try for free</Button>
                </a>
              </div>
            </FlexBox>
          </Container>
          <div className={styles['products-wave']}>
            <Wave color="black" />
          </div>
          <div className={styles['products-spacing']} />
        </div>
        <VerticalSpacing spacing="spacing-xxl" />
        <VerticalSpacing spacing="spacing-xxl" />
        <VerticalSpacing spacing="spacing-xxl" />
        <Header2 align="center">
          Thousands of teams trust weagileyou worldwide
        </Header2>
        <VerticalSpacing spacing="spacing-xxl" />
        <LogosSection />
        <VerticalSpacing spacing="spacing-xxl" />
        <Container className={styles['whats-new-containter']}>
          <Header2 align="center">What's new?</Header2>
          <VerticalSpacing spacing="spacing-l" />
          <Paragraph align="center">
            Learn from others, share your ideas, and extend your knowledge with
            a diverse group of people from around the world.
          </Paragraph>
          <VerticalSpacing spacing="spacing-l" />
          <Paragraph align="center">
            <a
              href="https://blog.weagileyou.com/"
              target="_blank"
              className={styles['read-more-link']}
            >
              <span>Read more in our Blog </span>
              <span>
                <Icon icon="back" />
              </span>
            </a>
          </Paragraph>
        </Container>
        <Container size="big">
          <FlexBox alignItems="start" className={styles['posts-flexbox']}>
            {posts.map((post) => {
              const author = authors.find(
                (author) => author.id === post.author,
              );
              return (
                <div className={styles['post-card']}>
                  <a href={post.guid.rendered} target="_blank">
                    <div className={styles['post-card__img']}>
                      <img src={post.jetpack_featured_media_url} />
                    </div>

                    <div className={styles['post-card__title']}>
                      <Header4>{post.title.rendered}</Header4>
                    </div>
                  </a>
                  <div
                    className={styles['post-card__text']}
                    dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                  />
                  {author && (
                    <Paragraph fontWeight="bold">By {author.name}</Paragraph>
                  )}
                </div>
              );
            })}
          </FlexBox>
        </Container>
        <VerticalSpacing spacing="spacing-xxl-4" />
        <VerticalSpacing spacing="spacing-xxl-4" />
      </div>
      <Footer />
    </>
  );
}
