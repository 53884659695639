import { Button, Icon } from '@we-agile-you/react-base';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { throttle } from 'lodash';

import styles from './Header.module.scss';
import { Link } from '../Link/Link';
import { LogoWay } from '../LogoWay/LogoWay';

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleWindowScroll = throttle(() => {
      setIsScrolled(
        document.body.scrollTop > 20 || document.documentElement.scrollTop > 20,
      );
    }, 50);

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  const className = cx(styles['header'], {
    [styles['header--scrolled']]: isScrolled,
  });

  return (
    <>
      <header className={className}>
        <div className={styles['left']}>
          <Link
            to="/"
            className={styles['logo-way-link']}
            title="Retrospective Online"
            onClick={() => window.scroll({ top: 0 })}
          >
            <LogoWay />
          </Link>
          <a
            className={styles['header-link']}
            href="https://blog.weagileyou.com/"
            target="_blank"
          >
            Blog
          </a>
        </div>
        <div className={styles['right']}>
          <a href="https://planningpokeronline.com/" target="_blank">
            <Button icon={<Icon icon="ppo" />}>
              Planning Poker
              <span className={styles['ppo-label']}> Online</span>
            </Button>
          </a>
        </div>
      </header>
    </>
  );
};
