import React from 'react';
import { Helmet } from 'react-helmet';
import LandingTemplate from '../templates/LandingTemplate/LandingTemplate';

export default function Home() {
  return (
    <>
      <Helmet title="Web tools for agile development teams" />
      <LandingTemplate />
    </>
  );
}
