import React from 'react';

export const UserNew = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8C9.65685 8 11 6.65685 11 5C11 3.34315 9.65685 2 8 2C6.34315 2 5 3.34315 5 5C5 6.65685 6.34315 8 8 8ZM13 5C13 6.48042 12.3566 7.81056 11.3342 8.72607C11.7693 8.92577 12.1833 9.16333 12.5721 9.4345C13.0251 9.75046 13.1361 10.3738 12.8202 10.8268C12.5042 11.2798 11.8809 11.3908 11.4279 11.0749C10.4564 10.3973 9.27611 10 8 10C4.68629 10 2 12.6863 2 16C2 16.5523 1.55228 17 1 17C0.447715 17 0 16.5523 0 16C0 12.7718 1.91213 9.99012 4.66559 8.72588C3.6433 7.81038 3 6.48032 3 5C3 2.23858 5.23858 0 8 0C10.7614 0 13 2.23858 13 5ZM23.435 20.6067C24.6066 19.4351 24.6066 17.5356 23.435 16.364L19.5394 12.4684C18.938 11.867 18.1097 11.5492 17.2604 11.5939L14.5734 11.7353C13.0406 11.8159 11.8159 13.0406 11.7352 14.5734L11.5938 17.2605C11.5491 18.1097 11.8669 18.9381 12.4683 19.5395L16.364 23.4351C17.5355 24.6067 19.435 24.6067 20.6066 23.4351L23.435 20.6067ZM22.0208 17.7782C22.4113 18.1688 22.4113 18.8019 22.0208 19.1925L19.1924 22.0209C18.8019 22.4114 18.1687 22.4114 17.7782 22.0209L13.8825 18.1252C13.6821 17.9248 13.5761 17.6487 13.591 17.3656L13.7324 14.6786C13.7593 14.1676 14.1676 13.7594 14.6785 13.7325L17.3655 13.5911C17.6486 13.5762 17.9247 13.6821 18.1252 13.8826L22.0208 17.7782ZM16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17Z"
      fill="currentColor"
    />
  </svg>
);
