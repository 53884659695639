import React from 'react';

export const Logo = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 200 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.6832 0.831446L99.7663 0L99.855 0.887329L99.7663 0.385992L99.6832 0.831446ZM155.224 154.397C136.151 154.397 119.683 142.262 113.552 124.991C117.146 122.305 119.953 118.623 121.563 114.354L139.623 117.852C143.181 118.541 145.713 114.48 143.529 111.589L123.08 84.5258V54.5788C123.08 41.49 112.469 30.8793 99.3804 30.8793C86.2915 30.8793 75.6808 41.49 75.6808 54.5788V84.5257L55.2321 111.589C53.0478 114.48 55.5801 118.541 59.1372 117.852L77.1977 114.354C78.8977 118.863 81.9328 122.716 85.8208 125.433C79.5679 142.469 63.2187 154.397 44.3085 154.397C3.89039 154.397 -15.3437 104.645 14.5633 77.4573L99.7663 0L184.969 77.4573C214.876 104.645 195.642 154.397 155.224 154.397ZM111.23 66.4523C111.23 73.0011 105.925 78.31 99.3803 78.31C92.8359 78.31 87.5306 73.0011 87.5306 66.4523C87.5306 59.9035 92.8359 54.5946 99.3803 54.5946C105.925 54.5946 111.23 59.9035 111.23 66.4523ZM70.5013 171.38C81.6929 167.057 90.9932 158.497 95.8518 147.62C96.5933 145.96 101.712 145.973 102.438 147.639C107.161 158.472 116.154 167.005 127.138 171.338C135.791 174.752 143.589 182.009 143.589 191.312C143.589 192.242 142.835 192.996 141.905 192.996H55.7231C54.7929 192.996 54.0388 192.242 54.0388 191.312C54.0388 182.009 61.8239 174.731 70.5013 171.38Z"
      fill="currentColor"
    />
  </svg>
);
