import React from 'react';

export const Retro = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0226 12.6942C8.74265 11.0742 7.48265 8.3642 7.69265 5.5742L7.93265 2.5842C8.00265 1.6042 8.79265 0.824198 9.77265 0.744198L12.7526 0.514198C15.5426 0.304198 18.2427 1.5642 19.8526 3.8642L22.5326 7.6142L24.7626 7.1642C26.6826 6.7742 28.6626 7.3742 30.0526 8.7642C30.3726 9.0842 30.3327 9.6142 29.9627 9.8742L26.1227 12.6242L26.8626 13.6542C27.4326 14.4542 27.3426 15.5442 26.6526 16.2342L23.3926 19.4942C22.7027 20.1842 21.6126 20.2742 20.8126 19.7042L19.7826 18.9642L17.0326 22.8042C16.7726 23.1742 16.2426 23.2142 15.9226 22.8942C14.5426 21.5142 13.9426 19.5242 14.3226 17.6042L14.7726 15.3742L11.0226 12.6942ZM15.8326 5.1242C16.8126 6.1042 16.8126 7.6842 15.8326 8.6642C14.8526 9.6442 13.2726 9.6442 12.2926 8.6642C11.3126 7.6842 11.3126 6.1042 12.2926 5.1242C13.2726 4.1442 14.8526 4.1442 15.8326 5.1242ZM13.1234 37.5241C24.8834 44.7541 37.5534 30.7441 30.1034 19.3341V19.3241C28.1234 16.4041 23.8934 19.4641 26.0434 22.2541C30.7134 27.8141 26.5534 36.5741 19.2934 36.9641C10.9934 37.6241 5.66341 28.0441 10.0234 21.0741C6.05341 26.1241 7.64341 34.1441 13.1234 37.5241Z"
      fill="currentColor"
    />
  </svg>
);
