import React from 'react';
import { Container } from '@we-agile-you/react-base';
import styles from './LogosSection.module.scss';

// logos
import microsoft from './logos/microsoft.svg';
import hpe from './logos/hpe.svg';
import mastercard from './logos/mastercard.svg';
import asics from './logos/asics.svg';
import deloitte from './logos/deloitte.svg';
import ing from './logos/ing.svg';
import bose from './logos/bose.svg';
import coinbase from './logos/coinbase.svg';
import gameloft from './logos/gameloft.svg';
import getyour from './logos/get-your.svg';
import motorola from './logos/motorola.svg';
import nasdaq from './logos/nasdaq.svg';
import nokia from './logos/nokia.svg';
import rovio from './logos/rovio.svg';
import thyssen from './logos/thyssen.svg';
import visa from './logos/visa.svg';
import google from './logos/google.svg';
import figma from './logos/figma.svg';

export const LogosSection = () => {
  return (
    <Container className={styles['logos-section']}>
      <ul className={styles['logos-list']}>
        <li key="logo-Asics">
          <img src={asics} alt="Asics" />
        </li>
        <li key="logo-Mastercard">
          <img src={mastercard} alt="Mastercard" />
        </li>
        <li key="logo-ING">
          <img src={ing} alt="ING" />
        </li>
        <li key="logo-bose">
          <img src={bose} alt="Bosse" />
        </li>
        <li key="logo-coinbase">
          <img src={coinbase} alt="Coinbase" />
        </li>
        <li key="logo-Deloitte">
          <img src={deloitte} alt="Deloitte" />
        </li>
        <li key="logo-gameloft">
          <img src={gameloft} alt="Gameloft" />
        </li>
        <li key="logo-getyour">
          <img src={getyour} alt="get your" />
        </li>
        <li key="logo-motorola">
          <img src={motorola} alt="Motorola" />
        </li>
        <li key="logo-Microsoft">
          <img src={microsoft} alt="Microsoft" />
        </li>
        <li key="logo-nasdaq">
          <img src={nasdaq} alt="Nasdaq" />
        </li>
        <li key="logo-google">
          <img src={google} alt="Google" />
        </li>
        <li key="logo-nokia">
          <img src={nokia} alt="Nokia" />
        </li>
        <li key="logo-figma">
          <img src={figma} alt="Figma" />
        </li>
        <li key="logo-rovio">
          <img src={rovio} alt="Rovio" />
        </li>
        <li key="logo-thyssen">
          <img src={thyssen} alt="Thyssen" />
        </li>
        <li key="logo-HP">
          <img src={hpe} alt="HP" />
        </li>
        <li key="logo-visa">
          <img src={visa} alt="Visa" />
        </li>
      </ul>
    </Container>
  );
};
