import React from 'react';

export const Export = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8889 22.0002C21.5025 22.0002 22 21.4946 22 20.8709L22 14.0764C22 13.4627 21.5025 12.9653 20.8889 12.9653C20.2752 12.9653 19.7778 13.4627 19.7778 14.0764L19.7778 19.7415L4.22222 19.7415L4.22222 14.0764C4.22222 13.4627 3.72476 12.9653 3.11111 12.9653C2.49746 12.9653 2 13.4627 2 14.0764L2 20.8709C2 21.4946 2.49746 22.0002 3.11111 22.0002L20.8889 22.0002ZM12 3C12.5523 3 13 3.44771 13 4L13 12.2397L15.636 9.61295C16.0265 9.22379 16.6597 9.22379 17.0502 9.61295C17.4407 10.0021 17.4407 10.6331 17.0502 11.0222L12.7071 15.3502C12.3165 15.7393 11.6834 15.7393 11.2929 15.3502L6.94971 11.0222C6.55919 10.6331 6.55919 10.0021 6.94971 9.61295C7.34023 9.22379 7.9734 9.22379 8.36392 9.61295L11 12.2398L11 4C11 3.44771 11.4477 3 12 3Z"
      fill="currentColor"
    />
  </svg>
);
