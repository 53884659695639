import {
  ButtonLink,
  FlexBox,
  Header4,
  Paragraph,
  VerticalSpacing,
} from '@we-agile-you/react-base';
import React from 'react';
import { LogoWay } from '../LogoWay/LogoWay';
import { Wave } from '../Wave/Wave';

import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles['footer']}>
      <Wave color="black" className={styles['wave']} />
      <div className={styles['content']}>
        <div>
          <LogoWay isLight />
        </div>
        <div className={styles['sections']}>
          <FlexBox justifyContent="center" alignItems="start">
            <div>
              <Header4 color="white">Products</Header4>
              <VerticalSpacing spacing="spacing-l" />
              <a href="https://planningpokeronline.com/" target="_blank">
                <ButtonLink fontWeight="normal" buttonColor="grey500">
                  Planning Poker Online
                </ButtonLink>
              </a>
              <VerticalSpacing spacing="spacing-m" />
              <a href="https://retrospectiveonline.com/" target="_blank">
                <ButtonLink fontWeight="normal" buttonColor="grey500">
                  Retrospective Online
                </ButtonLink>
              </a>
              <VerticalSpacing spacing="spacing-m" />
              <a
                href="https://planningpokeronline.com/terms-and-conditions"
                target="_blank"
              >
                <ButtonLink fontWeight="normal" buttonColor="grey500">
                  Terms
                </ButtonLink>
              </a>
            </div>
          </FlexBox>
          <FlexBox justifyContent="center" alignItems="start">
            <div>
              <Header4 color="white">Connect</Header4>
              <VerticalSpacing spacing="spacing-l" />
              <a
                href="https://www.linkedin.com/company/planning-poker-online-scrum"
                target="_blank"
              >
                <ButtonLink fontWeight="normal" buttonColor="grey500">
                  LinkedIn
                </ButtonLink>
              </a>
            </div>
          </FlexBox>
          <FlexBox justifyContent="center" alignItems="start">
            <div>
              <Header4 color="white">Legal</Header4>
              <VerticalSpacing spacing="spacing-l" />
              <a
                href="https://planningpokeronline.com/legal-notice/"
                target="_blank"
              >
                <ButtonLink fontWeight="normal" buttonColor="grey500">
                  Legal notice
                </ButtonLink>
              </a>
              <VerticalSpacing spacing="spacing-l" />
              <a
                href="https://planningpokeronline.com/cookie-policy/"
                target="_blank"
              >
                <ButtonLink fontWeight="normal" buttonColor="grey500">
                  Cookie policy
                </ButtonLink>
              </a>
              <VerticalSpacing spacing="spacing-l" />
              <a
                href="https://planningpokeronline.com/privacy-policy/"
                target="_blank"
              >
                <ButtonLink fontWeight="normal" buttonColor="grey500">
                  Privacy policy
                </ButtonLink>
              </a>
            </div>
          </FlexBox>
        </div>
      </div>
      <VerticalSpacing spacing="spacing-xxl-4" />
      <Paragraph align="center" color="grey500" size="small">
        We Agile You ®
      </Paragraph>
      <VerticalSpacing spacing="spacing-xxl-4" />
      <VerticalSpacing spacing="spacing-xxl-4" />
    </footer>
  );
};
