import React from 'react';

export const Detach = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C12.5523 0 13 0.447715 13 1V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V1C11 0.447715 11.4477 0 12 0ZM5.13397 1.5C5.61227 1.22386 6.22386 1.38773 6.5 1.86603L7.5 3.59808C7.77614 4.07637 7.61227 4.68796 7.13397 4.9641C6.65568 5.24024 6.04409 5.07637 5.76795 4.59808L4.76795 2.86603C4.49181 2.38773 4.65568 1.77614 5.13397 1.5ZM19.232 2.86603C19.5082 2.38773 19.3443 1.77614 18.866 1.5C18.3877 1.22386 17.7761 1.38773 17.5 1.86603L16.5 3.59808C16.2239 4.07637 16.3877 4.68796 16.866 4.9641C17.3443 5.24024 17.9559 5.07637 18.232 4.59808L19.232 2.86603ZM23 10C23 8.34315 21.6569 7 20 7L15.4142 7C14.8619 7 14.4142 7.44771 14.4142 8C14.4142 8.55228 14.8619 9 15.4142 9H20C20.5523 9 21 9.44771 21 10V14C21 14.5523 20.5523 15 20 15H15.4142C14.8619 15 14.4142 15.4477 14.4142 16C14.4142 16.5523 14.8619 17 15.4142 17H20C21.6569 17 23 15.6569 23 14V10ZM1 14C1 15.6569 2.34314 17 4 17H8.58579C9.13807 17 9.58579 16.5523 9.58579 16C9.58579 15.4477 9.13807 15 8.58579 15H4C3.44772 15 3 14.5523 3 14L3 10C3 9.44771 3.44772 9 4 9L8.58579 9C9.13807 9 9.58579 8.55229 9.58579 8C9.58579 7.44771 9.13807 7 8.58579 7L4 7C2.34315 7 1 8.34314 1 10V14ZM11 23C11 23.5523 11.4477 24 12 24C12.5523 24 13 23.5523 13 23V20C13 19.4477 12.5523 19 12 19C11.4477 19 11 19.4477 11 20V23ZM17.5 22.134C17.7761 22.6123 18.3877 22.7761 18.866 22.5C19.3443 22.2239 19.5082 21.6123 19.232 21.134L18.232 19.4019C17.9559 18.9236 17.3443 18.7598 16.866 19.0359C16.3877 19.312 16.2239 19.9236 16.5 20.4019L17.5 22.134ZM5.13397 22.5C4.65568 22.2239 4.49181 21.6123 4.76795 21.134L5.76795 19.4019C6.04409 18.9236 6.65568 18.7598 7.13397 19.0359C7.61227 19.312 7.77614 19.9236 7.5 20.4019L6.5 22.134C6.22386 22.6123 5.61227 22.7761 5.13397 22.5Z"
      fill="currentColor"
    />
  </svg>
);
