import React from 'react';

export const UserPlus = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 18.1111C20 18.602 19.602 19 19.1111 19C18.6202 19 18.2222 18.602 18.2222 18.1111V17.3C18.2222 14.0198 15.4302 11.35 12 11.35C8.56889 11.35 5.77778 14.0198 5.77778 17.3V18.1111C5.77778 18.602 5.37981 19 4.88889 19C4.39797 19 4 18.602 4 18.1111V17.3C4 14.144 6.00978 11.4291 8.86933 10.2612C7.79644 9.40265 7.11111 8.11405 7.11111 6.675C7.11405 4.09423 9.30116 2.00281 12 2C14.6951 2 16.8889 4.0978 16.8889 6.675C16.8885 8.06067 16.2443 9.37454 15.1307 10.2612C17.9902 11.4291 20 14.1431 20 17.3V18.1111ZM12 3.7C10.2844 3.7 8.88889 5.0345 8.88889 6.675C8.88889 8.3155 10.2844 9.65 12 9.65C13.7156 9.65 15.1111 8.3155 15.1111 6.675C15.1111 5.0345 13.7156 3.7 12 3.7ZM11.06 15.02C11.06 14.4567 11.5167 14 12.08 14C12.6433 14 13.1 14.4567 13.1 15.02V17H15.16C15.7123 17 16.16 17.4477 16.16 18C16.16 18.5523 15.7123 19 15.16 19H13.1V20.98C13.1 21.5433 12.6433 22 12.08 22C11.5167 22 11.06 21.5433 11.06 20.98V19H9C8.44771 19 8 18.5523 8 18C8 17.4477 8.44772 17 9 17H11.06V15.02Z"
      fill="currentColor"
    />
  </svg>
);
