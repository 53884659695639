import cx from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ButtonLink } from '../../ButtonLink/ButtonLink';

import styles from './Paragraph.module.scss';

interface ParagraphProps {
  children: ReactNode;
  color?:
    | 'black'
    | 'primary-white'
    | 'grey600'
    | 'grey500'
    | 'danger'
    | 'success'
    | 'info'
    | 'white';
  align?: 'left' | 'center' | 'right';
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'small' | 'micro' | 'nano';
  fontWeight?: 'normal' | 'bold';
  id?: string;
  className?: string;
  sizeSmallScreen?: 'body' | 'small' | 'micro' | 'nano';
  truncateLines?: number;
  style?: React.CSSProperties;
}

export const Paragraph = ({
  children,
  sizeSmallScreen,
  id,
  align,
  className,
  style,
  truncateLines,
  ...props
}: ParagraphProps) => {
  const color = props.color || 'black';
  const size = props.size || 'body';
  const fontWeight = props.fontWeight || 'normal';

  const ref = useRef<HTMLParagraphElement>(null);

  const [isTruncated, setIsTruncated] = useState<Boolean | null>(null);
  const [isExpanded, setIsExpanded] = useState<Boolean | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    if (
      typeof truncateLines !== 'undefined' &&
      ref.current.scrollHeight > ref.current.clientHeight
    ) {
      setIsTruncated(true);
    }
  }, [truncateLines]);

  const renderParagraph = () => (
    <p
      id={id}
      ref={ref}
      style={
        truncateLines && !isExpanded
          ? {
              ...style,
              display: '-webkit-box',
              WebkitLineClamp: truncateLines,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }
          : style
      }
      className={cx(
        styles.paragraph,
        styles[`paragraph--${color}`],
        align && styles[`paragraph--${align}`],
        styles[`paragraph--${size}`],
        styles[`paragraph--weight-${fontWeight}`],
        sizeSmallScreen && styles[`paragraph--small-screen-${sizeSmallScreen}`],
        className,
      )}
    >
      {children}
    </p>
  );

  return isTruncated && !isExpanded ? (
    <div>
      {renderParagraph()}
      <ButtonLink
        size="micro"
        onClick={() => setIsExpanded(true)}
        fontWeight="normal"
      >
        Show more
      </ButtonLink>
    </div>
  ) : (
    renderParagraph()
  );
};
