import React from 'react';

export const Invite = () => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2929 18.0752C21.2929 18.586 20.8789 19 20.3681 19C19.8574 19 19.4433 18.586 19.4433 18.0752V17.3C19.4433 14.0198 16.5385 11.35 12.9697 11.35C9.39995 11.35 6.49607 14.0198 6.49607 17.3V18.0752C6.49607 18.586 6.08202 19 5.57127 19C5.06051 19 4.64646 18.586 4.64646 18.0752V17.3C4.64646 14.144 6.73744 11.4291 9.71254 10.2612C8.5963 9.40265 7.88327 8.11405 7.88327 6.675C7.88633 4.09423 10.1618 2.00281 12.9697 2C15.7737 2 18.0561 4.0978 18.0561 6.675C18.0557 8.06067 17.3855 9.37454 16.2269 10.2612C19.2019 11.4291 21.2929 14.1431 21.2929 17.3V18.0752ZM12.9697 3.7C11.1848 3.7 9.73288 5.0345 9.73288 6.675C9.73288 8.3155 11.1848 9.65 12.9697 9.65C14.7546 9.65 16.2065 8.3155 16.2065 6.675C16.2065 5.0345 14.7546 3.7 12.9697 3.7ZM11.9917 15.0612C11.9917 14.4751 12.4668 14 13.0529 14C13.639 14 14.1141 14.4751 14.1141 15.0612V17H16.2978C16.8501 17 17.2978 17.4477 17.2978 18C17.2978 18.5523 16.8501 19 16.2978 19H14.1141V20.9388C14.1141 21.5249 13.639 22 13.0529 22C12.4668 22 11.9917 21.5249 11.9917 20.9388V19H9.80808C9.25579 19 8.80808 18.5523 8.80808 18C8.80808 17.4477 9.25579 17 9.80808 17H11.9917V15.0612Z"
      fill="currentColor"
    />
  </svg>
);
