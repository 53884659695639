import React from 'react';

export const Cards = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1752 0.741078C10.3181 0.207612 10.8665 -0.10897 11.3999 0.0339714L22.991 3.1398C23.5245 3.28274 23.8411 3.83108 23.6981 4.36454L19.557 19.8194C19.4141 20.3528 18.8658 20.6694 18.3323 20.5265L16.2399 19.9658C15.7065 19.8229 15.3899 19.2745 15.5328 18.7411C15.6758 18.2076 16.2241 17.891 16.7576 18.034L17.884 18.3358L21.5075 4.81283L11.8482 2.22464L11.4659 3.65136C11.323 4.18482 10.7746 4.5014 10.2412 4.35846C9.70771 4.21552 9.39113 3.66718 9.53407 3.13372L10.1752 0.741078ZM0 7.00001C0 6.44772 0.447715 6.00001 1 6.00001H13C13.5523 6.00001 14 6.44772 14 7.00001V23C14 23.5523 13.5523 24 13 24H1C0.447715 24 0 23.5523 0 23V7.00001ZM2 8.00001V22H12V8.00001H2ZM7.00009 18L9.55894 15.4932C10.1473 14.9164 10.147 14.0108 9.55819 13.431C8.98939 12.8764 8.02431 12.8749 7.45476 13.4318L7.26641 13.6163C7.19582 13.6856 7.10001 13.7245 7.00009 13.7245C6.90018 13.7245 6.80437 13.6856 6.73377 13.6163L6.54543 13.4318C6.26141 13.1535 5.88848 13 5.49484 13C5.1012 13 4.72677 13.1535 4.44049 13.4325C3.85323 14.0108 3.85286 14.9164 4.44124 15.4932L7.00009 18Z"
      fill="currentColor"
    />
  </svg>
);
